<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
        <path d="M1.66666 15.8333C1.66666 17.25 2.74999 18.3333 4.16666 18.3333H15.8333C17.25 18.3333 18.3333 17.25 18.3333 15.8333V9.16663H1.66666V15.8333ZM15.8333 3.33329H14.1667V2.49996C14.1667 1.99996 13.8333 1.66663 13.3333 1.66663C12.8333 1.66663 12.5 1.99996 12.5 2.49996V3.33329H7.49999V2.49996C7.49999 1.99996 7.16666 1.66663 6.66666 1.66663C6.16666 1.66663 5.83332 1.99996 5.83332 2.49996V3.33329H4.16666C2.74999 3.33329 1.66666 4.41663 1.66666 5.83329V7.49996H18.3333V5.83329C18.3333 4.41663 17.25 3.33329 15.8333 3.33329Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
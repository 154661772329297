<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M10.6 8.80005C10.5333 8.80005 10.5333 8.73338 10.4666 8.73338C11.4666 8.00005 12 6.86671 12 5.60005C12 5.46672 12 5.33338 12 5.20005V5.13338L12.2 3.53338C12.3333 2.46672 11.6 1.53338 10.6 1.33338L9.99998 1.20005C8.66665 1.00005 7.33331 1.00005 5.99998 1.20005L5.46665 1.33338C4.39998 1.53338 3.66665 2.46672 3.79998 3.53338L3.99998 5.13338V5.20005C3.99998 5.33338 3.99998 5.46672 3.99998 5.60005C3.99998 6.80005 4.53331 8.00005 5.53331 8.73338C5.46665 8.73338 5.46665 8.80005 5.39998 8.80005C3.19998 9.73338 1.66665 11.8 1.39998 14.2C1.33331 14.5334 1.59998 14.8667 1.99998 14.9334C2.39998 15 13.6666 14.9334 14 14.9334H14.0666C14.4 14.8667 14.6666 14.5334 14.6666 14.2C14.3333 11.8 12.8 9.73338 10.6 8.80005ZM7.99998 10.8667L6.86665 9.73338C7.59998 9.60005 8.33331 9.60005 9.06665 9.73338L7.99998 10.8667ZM7.99998 8.26672C6.53331 8.26672 5.39998 7.13338 5.33331 5.66672H10.6666C10.6 7.13338 9.46665 8.26672 7.99998 8.26672Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
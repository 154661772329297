<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 25" fill="none">
        <path d="M12 21.5C7.02891 21.5 3 17.4711 3 12.5C3 7.52891 7.02891 3.5 12 3.5C16.9711 3.5 21 7.52891 21 12.5C21 17.4711 16.9711 21.5 12 21.5ZM11.1562 7.71875V12.5C11.1562 12.7812 11.2969 13.0449 11.5324 13.2031L14.9074 15.4531C15.2941 15.7133 15.818 15.6078 16.0781 15.2176C16.3383 14.8273 16.2328 14.307 15.8426 14.0469L12.8438 12.05V7.71875C12.8438 7.25117 12.4676 6.875 12 6.875C11.5324 6.875 11.1562 7.25117 11.1562 7.71875Z" fill="#4F4F4F"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>
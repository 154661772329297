import appointmentPb from "../modules/core/grpc/generated/appointment_pb";
import appointmentService from "../modules/core/services/appointmentService"

export const ListPatientAppointmentDecision = async (obj: any) => {
    try {
        const req = new appointmentPb.ListPatientAppointmentDecisionRequest();
        req.setAppointmentid(obj.appointmentId);
        req.setPatientid(obj.patientId);           

        const res = await appointmentService.listPatientAppointmentDecision(req);

        console.log("res actions", res)

        return res;
    } catch (error) {
        return error;
    }
}

export const PatientAppointmentDecision = async (appoinmentParams: any) => {
    try {
        const req = new appointmentPb.PatientAppointmentDecisionRequest();
        req.setAppointmentid(appoinmentParams.appointmentId);
        req.setPatientid(appoinmentParams.patientId);     
        req.setEmail(appoinmentParams.email);     
        req.setAction(appoinmentParams.decision.value);

        const res = await appointmentService.patientAppointmentDecision(req);

        

        return res;
    } catch (error) {
        return error;
    }
}